import TreeNode from "./TreeNode";
const TreeView = ({treeNodes, toggleExpanded, toggleVisible, displaymode, onContextmenu}) =>{

    return (
        <ul className='TreeView'>
            {treeNodes.map(treeNode => (<>{<TreeNode treeNode={treeNode}
                                                     toggleExpanded={toggleExpanded}
                                                     toggleVisible={toggleVisible}
                                                     displaymode={displaymode}
                                                     onContextmenu={onContextmenu}
                                                     key = {treeNode.key}
            />}</>))}
        </ul>
  );
}
//
export default TreeView;