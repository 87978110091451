import NavBar from "./NavBar";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ContactPage from "./ContactPage";
import NotFoundPage from "./NotFoundPage";
import CirclesPage from "./CirclesPage";
import ThreeScenePage from "./ThreeScenePage";
import ThreeSceneTreePage from "./ThreeSceneTreePage";

function App() {
  return (
    <Router>
        <div className="App">
          <NavBar />
          <div className= "content">
            <Routes>
                <Route exact path='/' element={<ThreeScenePage/>}/>
                <Route exact path='/circles' element={<CirclesPage/>}/>
                <Route exact path='/contact' element={<ContactPage/>}/>
                <Route exact path='/three_scene' element={<ThreeScenePage/>}/>
                <Route exact path='/three_scene_tree' element={<ThreeSceneTreePage/>}/>
                <Route path='*' exact={true} element={NotFoundPage}/>
            </Routes>
          </div>
        </div>
    </Router>
  );
}

export default App;
