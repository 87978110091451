// von hier aus rufe ich die ThreeFiberkomponente auf
import ThreefiberComponent from "./ThreefiberComponent";
import Settings3D from "./Settings3D";
import {useRef} from "react";

const ThreeScenePage = () =>{
    const threefiberComponentRef = useRef(null);

    let file_scale = {
        file: 'turan_villa.fbx',
        scale: 0.01
    };
    // TODO: ich muss mir überlegen, welche Startparameter ich wirklich brauche
    // control = 0: 1st Person Control'
    // control = 1: Orbit Control


    return (
        <div className="TreeScene">
            <Settings3D threefiberComponentRef={threefiberComponentRef}/>
            <ThreefiberComponent ref={threefiberComponentRef}
                                 file_scale    = {file_scale}
                                 control = {0}
            />
        </div>
    );
}

export default ThreeScenePage;
