import {useState, useEffect, forwardRef, useImperativeHandle} from "react";
import TreeView from "./TreeView";



const TreeBase = forwardRef((props, ref) =>{
    let sceneTree = props.sceneTree;

    useImperativeHandle(ref, ()=>({
        refreshTree(props){console.log("useImperativeHandle.refreshTree", props); sceneTree = props;  setZ(z+1);},

    }));

    const [z, setZ]=useState(0);
    const [displaymode, setDisplaymode]=useState(0);
    let contextMenuTitle = '';


    useEffect(() =>{// diese Funktion wird aufgerufen, nach einem Rendering, egal was gerendert wird.
        const handleResize = e => {
            //console.log("handleResize");
            let treeview = document.getElementById("Treeview");//.querySelector("Treeview");
            if (treeview)
            {
                let rect = treeview.getBoundingClientRect();
                console.log("rect treeview ", rect);
                treeview.style.height = window.innerHeight- rect.top;
                treeview.style.width = 400;
            }
        }

        handleResize();
        window.addEventListener("resize", handleResize);
    },[]);// []=einmalige ausführung bei der Initialisierung [xy] = ausführung, wenn xy geändert wird
    // das ist dann so eine Art Listener, der darauf wartet, dass eine Änderung kommt.


    const toggleExpanded = (tn) => {
        if (tn.expanded)
            tn.expanded = false;
        else
            tn.expanded = true;

        let a = z+1;
        setZ(a);
    }

    const toggleVisible = (tn) => {
        if (tn.visible)
            tn.visible = false;
        else
            tn.visible = true;

        let a = z+1;
        setZ(a);

        //console.log("toggleVisible", tn.id);
        props.setVisibleFromTree(tn);
    }

    const toggleDisplaymode = ()=>{
        if (displaymode == 0)
            setDisplaymode(1);
        else
            setDisplaymode(0);

        console.log("toggleDisplaymode", displaymode );
    }

    document.addEventListener('click', (e) => {
        const menu = document.getElementById('context-menu-tree');//document.querySelector('#context-menu');
        if (!menu)
            return;

        if (e.target.offsetParent != menu) {
            menu.setAttribute('hidden', 'true');
        }
    });


    document.addEventListener('keyup', (e) => {
        const menu = document.getElementById('context-menu-tree');//document.querySelector('#context-menu');
        if (e.key === 'Escape') {
            menu.setAttribute('hidden', 'true');
        }
    });

    const onContextmenu = (node, position)=>{
        const menu = document.getElementById('context-menu-tree');//document.querySelector('#context-menu');
        console.log("oncontextmenü ", menu);
        menu.style.top  = `${position.y}px`;
        menu.style.left = `${position.x}px`;
        let menutitle = document.getElementById("title");
        menutitle.innerText = node.name;

        console.log("toggleContextmenu", node.name, menutitle.innerText);
        menu.removeAttribute('hidden');
    }
//
    return (<>
        <div className="TreeBase" id="Treeview" style={ {height:window.innerHeight-120}}>
            <div style={{display: "flex" }}>
                <h2 id='treeheader'>Treeview</h2>
                <label>
                    <input type="checkbox" onChange={toggleDisplaymode}/>
                    Kompakt
                </label>
            </div>
            <div>
                {sceneTree && <TreeView treeNodes={sceneTree} toggleExpanded={toggleExpanded} toggleVisible={toggleVisible} displaymode={displaymode} onContextmenu={onContextmenu}/>}
            </div>
        </div>
            <div id="context-menu-tree" hidden>
                <b id="title" style={{paddingLeft: "10px"}} ></b>
                <menu style={{paddingLeft: "0px", marginLeft: "0px"}}>
                    <button id="hide" >ausblenden</button><br/>
                    <button id="show" >alles einblenden</button>
                </menu>
            </div>
        </>
    );
});


export default TreeBase;