import {Canvas} from "@react-three/fiber";
import {Stats} from "@react-three/drei";
import React, {useEffect, forwardRef, useImperativeHandle, useRef} from "react";
import ErrorBoundary from "./ErrorBoundary";
import Input from "./Input";
import RenderLoop from "./RenderLoop";
import {Joystick} from "react-joystick-component";
import {MobileView} from "react-device-detect";
import {cameraDirection} from './CameraDirection.js'
import {controlType} from "./controlType";



const ThreefiberComponent = forwardRef((props, ref) =>{

    const renderLoopRef = useRef(null);

    let inputvalues = {
        forward_backward: 0.0,
        left_right: 0.0,
        velocity: 0.005,
        moveStartTimestamp: 0,
        cameraLevel: 1.7,

        rot_left_right: 0.0,
        rot_up_down: 0.0,
        rotStartTimestamp: 0,
        enable_up_down: true,

        wheel_value: 50.0,
        setWheel: false,

        ambientLight: 1.0,
        setAmbientLight: true,

        camDirection: cameraDirection.Vorne,
        setCamDirection: false,

        controltype: controlType.firstPersonControl,
        setControl: false,

        id: [],
        visible: false,
        setVisible: false,
        allesEinblenden: false,
        updateCamera : true,
        canvas_width : 1,
        canvas_heigth : 1,
    };

    let file_scale = {
        file: 'turan_villa.fbx',
        scale: 0.01,
        setFileScale: true
    };


    useImperativeHandle(ref, ()=>({
        setAmbientLight(props){inputvalues.ambientLight = props; inputvalues.setAmbientLight = true; },
        setCameraLevel(props) {inputvalues.cameraLevel = props;},
        setVelocity(props)    {inputvalues.velocity = props;},
        setFile_Scale(props)  {file_scale.scale = props.scale; file_scale.file = props.file; file_scale.setFileScale = true;
                                     let canvas = document.querySelector("canvas");
                                     canvas.focus(); // Nach dem Laden einer Scene, wird der Focus auf das canvas gesetzt, damit man da direkt mit den Tastatureingaben den first-Person Controll bedienen kann.
                                    },
        setCameraDirection(props){inputvalues.camDirection = props; inputvalues.setCamDirection = true;},
        setControl(props)     {inputvalues.controltype = props; inputvalues.setControl = true;},
        setVisibilityInScene(id, visible){inputvalues.id = id; inputvalues.visible = visible; inputvalues.setVisible=true;}
    }));


    //TODO: wir benötigen beim Laden oder auch später bei dem Treecontrol eine Abbruchfunktion, wenn man wärend des Ladens
    // einen anderen Link aufruft. Dann laufen Lade-Prozesse und ähnliches weiter und dann später ins Leere.
    // siehe hierzu das Video https://www.youtube.com/watch?v=aKOQtGLT-Yk von dm React-Tutorial
    let canvas_top, canvas_left, canvas_width, canvas_heigth;

    useEffect(() =>{// diese Funktion wird aufgerufen, nach einem Rendering, egal was gerendert wird.
        const handleResize = e => {

            let canvas = document.querySelector("canvas");
            //canvas = document.querySelector("canvas");
            let rect = canvas.getBoundingClientRect();
            //console.log("rect ", rect);
            canvas.height = window.innerHeight- rect.top;//window.innerHeight;
            canvas.width = window.innerWidth - rect.left;//window.innerWidth;
            //console.log("canvas ", canvas.width, canvas.height);

            canvas_top = rect.top;
            canvas_left = rect.left;
            canvas_heigth = canvas.height;//rect.height - canvas_top;// Höhe und Breite stimmen nicht, man muss die linke obere Ecke "abziehen"
            canvas_width = canvas.width;//rect.width - canvas_left;
            inputvalues.updateCamera = true;
            inputvalues.canvas_width = canvas_width;
            inputvalues.canvas_heigth = canvas_heigth;
        };

        handleResize();
        window.addEventListener("resize", handleResize);
    },[]);// []=einmalige ausführung bei der Initialisierung [xy] = ausführung, wenn xy geändert wird
    // das ist dann so eine Art Listener, der darauf wartet, dass eine Änderung kommt.


    const handleJoystickMoveStart= (e)=>{
        inputvalues.moveStartTimestamp = Date.now();
    }
    const handleJoystickMove = (e)=>{
        // e.x = [-1, 1] (horizontal), e.y = [-1, 1] (vertical)
        inputvalues.left_right = e.x;
        inputvalues.forward_backward = -e.y;
    }
    const handleJoystickMoveStop= (e)=>{
        inputvalues.left_right  = 0.0;
        inputvalues.forward_backward  = 0.0;
    }

    const handleJoystickRotateStart = (e)=>{
        inputvalues.rotStartTimestamp = Date.now();
        inputvalues.enable_up_down = true;
    }
    const handleJoystickRotate = (e)=>{
        inputvalues.rot_left_right  = -e.x;
        inputvalues.rot_up_down  = -e.y * Math.abs(e.y); // durch das Quadrieren möchte ich die Empfindlichkeit reduzieren
        //console.log("inputvalues.rot_up_down " , inputvalues.rot_up_down);
    }
    const handleJoystickRotateStop= (e)=>{
        inputvalues.rot_left_right = 0.0;
        inputvalues.rot_up_down  = 0.0;
    }


    const ausblenden = ()=>{
        console.log("OnContextmenü ausblenden");
        if (objectIdentifiedByContextMenu)
            objectIdentifiedByContextMenu.visible = false;
    }
    const allesEinblenden = ()=>{
        console.log("OnContextmenü allesEinblenden");
        inputvalues.allesEinblenden = true;
    }


    document.addEventListener('click', (e) => {
        //console.log("eventlistener click",e, e.srcElement.localName);
        const menu = document.getElementById('context-menu-threefiber');//document.querySelector('#context-menu');
        if (!menu)
            return;

        if (e.srcElement.localName == 'button')
        {
            switch (e.srcElement.innerText){
                case 'alles einblenden':
                    menu.setAttribute('hidden', 'true');
                    allesEinblenden();
                    break;
                case 'ausblenden':
                    menu.setAttribute('hidden', 'true');
                    ausblenden();
                    break;
            }
            return;
        }

        if (e.target.offsetParent != menu) {
            if (menu)
            menu.setAttribute('hidden', 'true');
        }
    });


    document.addEventListener('keyup', (e) => {
        const menu = document.getElementById('context-menu-threefiber');//document.querySelector('#context-menu');
        if (!menu)
            return;
        if (e.key === 'Escape') {
            menu.setAttribute('hidden', 'true');
        }
    });

    // let canvas = document.querySelector("canvas");
    // canvas = document.querySelector("canvas");
    // canvas.height = window.innerHeight;
    // canvas.width = window.innerWidth;
    // canvas_heigth = canvas.height = window.innerHeight;
    // canvas_width = canvas.width = window.innerWidth;
    // canvas_top = canvas.style.top;
    // canvas_left = canvas.style.left;
    let objectIdentifiedByContextMenu=null;

    const contextMenu = (e)=>{
        const menu = document.getElementById('context-menu-threefiber');//document.querySelector('#context-menu');
        if (!menu)
            return;
        let menutitle = document.getElementById("title_threefiber");
        let canvas = document.querySelector("canvas");
        e.preventDefault();


        let position = {x: e.pageX, y: e.pageY};
        // Position des Kontextmenüs:
        menu.style.top  = `${position.y}px`;
        menu.style.left = `${position.x}px`;

        // Position der Maus umgerechnet in relative Koordinaten:
        let xrel, yrel;
        //console.log("x,y ",position.x, position.y, " width heigt ",canvas_width, canvas_heigth, " cl ct", canvas_left, canvas_top);
        // console.log("canvas ", canvas_left, canvas_top,  );
        position.x = position.x - canvas_left;
        position.y = position.y - canvas_top;
        //console.log("2. x,y ",position.x, position.y);
         xrel = (position.x / canvas_width) * 2 - 1;
         yrel = -(position.y / canvas_heigth) * 2 + 1;
        //console.log("xrel,yrel ", xrel, yrel);

        position.x = xrel;
        position.y = yrel;

        menutitle.innerText = "Hintergrund";
        objectIdentifiedByContextMenu = renderLoopRef.current.identifyObject(position);

        if (objectIdentifiedByContextMenu)
        {
            //console.log("obj identifiziert ", objectIdentifiedByContextMenu);
            menutitle.innerText = objectIdentifiedByContextMenu.name;
        }
        menu.removeAttribute('hidden');
    }



    //style={ {height:window.innerHeight-153, width: window.innerWidth - 400}}
    return (
        <>
        <div className='ThreeScene'>
            <ErrorBoundary fallback={<p>Something went wrong</p>}>
            <Canvas onContextMenu={contextMenu} >
                    <Input inputvalues = {inputvalues}/>
                    <ambientLight intensity={inputvalues.ambientLight}/>
                    <directionalLight color="white" position={[10000, 0, 0]}/>
                    <directionalLight color="white" position={[0, 10000, 0]}/>
                    <RenderLoop file_scale = {file_scale}
                                inputvalues ={inputvalues}
                                sceneTree = {props.sceneTree}
                                refreshTree ={props.refreshTree}
                                ref={renderLoopRef}
                    />
                <Stats />
            </Canvas>
            </ErrorBoundary>
        </div>
        <MobileView>
            <div className='bottom-left'>
                <Joystick size={100} sticky={false} baseColor="red" stickColor="blue" start = {handleJoystickMoveStart} move={handleJoystickMove}
                          stop={handleJoystickMoveStop}></Joystick>
            </div>
            <div className='bottom-right'>
                <Joystick size={100} sticky={false} baseColor="blue" stickColor="red" start = {handleJoystickRotateStart} move={handleJoystickRotate}
                          stop={handleJoystickRotateStop}></Joystick>
            </div>
        </MobileView>
            <div id="context-menu-threefiber" hidden>
                <b id="title_threefiber" style={{paddingLeft: "10px"}} ></b>
                <menu style={{paddingLeft: "0px", marginLeft: "0px"}}>
                    <button id="hide">ausblenden</button><br/>
                    <button id="show">alles einblenden</button>
                </menu>
            </div>
       </>
    );
});

export default ThreefiberComponent;
