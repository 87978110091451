import * as THREE from "three";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {FBXLoader} from "three/addons/loaders/FBXLoader";
import {STLLoader} from "three/addons/loaders/STLLoader";
import {cameraDirection} from './CameraDirection.js'
import {controlType} from "./controlType";
export function getIntersection (scene, pos, vec){
    const raycaster = new THREE.Raycaster();
    // Ausgehend von einer Position wird in Richtung "vec" der am dichtesten zu "pos" liegende Schnitt gesucht
    raycaster.set(pos, vec);
    const intersects = raycaster.intersectObjects( scene.children );// Schnitt mit allen Objekten der Szene
    //console.log("intersects ", intersects);
    if (intersects.length == 0){
        return -1;// wenn es keinen Schnittpunkt gibt, gebe ich -1 zurück, damit die aufrufende Stelle entsprechend reagieren kann.
    }
    else{
        let distance = -1;

        for ( let i = 0; i < intersects.length; i ++ ) {
            // ich suche den Punkt mit der kürzesten Distanz:
            if (distance == -1 || intersects[i].distance < distance){
                distance = intersects[i].distance;
            }
        }
        return distance;
    }
}

export function identifyObject(scene, x,y, camera, renderer){

    let mousepos = new THREE.Vector2();
    //mousepos.set((x / renderer.domElement.clientWidth) * 2 - 1, -(y / renderer.domElement.clientHeight) * 2 + 1);
    mousepos.set(x,y);
    console.log("identifyObject", mousepos.x, mousepos.y);
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mousepos, camera);
    const intersects = raycaster.intersectObjects( scene.children );// Schnitt mit allen Objekten der Szene

    if (intersects.length == 0){
        return null;// wenn es keinen Schnittpunkt gibt, gebe ich -1 zurück, damit die aufrufende Stelle entsprechend reagieren kann.
    }
    else{
        let distance = -1;
        let idx = -1;

        for ( let i = 0; i < intersects.length; i ++ ) {
            // ich suche den Punkt mit der kürzesten Distanz:
            if (distance == -1 || intersects[i].distance < distance){
                distance = intersects[i].distance;
                idx = i;
            }
        }
        // let n = intersects[idx].object.name.toString();
        console.log("idenfyObjekt ", intersects[idx]);
        return intersects[idx].object;
    }
}

export function centerScene (scene, control, bbox)  {
        getBBox(scene, bbox);
        let mittelpunkt= new THREE.Vector3();

        // Wenn ich Orbitcontrol verwende, wird eine komplette Zentrierung gemacht.
        // beim 1`st Person Control wird die Zentrierung nur auf der xz Ebene vorgenommen. d.h. die Höhe (y-wert) wird nicht geändert.
        if (control == controlType.firstPersonControl)
            mittelpunkt.set((bbox.max.x + bbox.min.x) / 2, (bbox.max.y + bbox.min.y) / 2, (bbox.max.z + bbox.min.z) / 2);
        else
            mittelpunkt.set((bbox.max.x + bbox.min.x) / 2, 0, (bbox.max.z + bbox.min.z) / 2);

        for (let i=0; i< scene.children.length; i++) {
            if (scene.children[i].type == 'Group'){
                scene.children[i].position.set(scene.children[i].position.x - mittelpunkt.x, scene.children[i].position.y - mittelpunkt.y, scene.children[i].position.z - mittelpunkt.z);
                break;
            }
        }
        // ich berechne die Boundingbox neu, da ich daraufhin die Cameraposition leicht außerhalb setzen kann.
        bbox.min.set(bbox.min.x - mittelpunkt.x, bbox.min.y - mittelpunkt.y, bbox.min.z - mittelpunkt.z);
        bbox.max.set(bbox.max.x - mittelpunkt.x, bbox.max.y - mittelpunkt.y, bbox.max.z - mittelpunkt.z);
}

export function getBBox (scene, bbox){
    let gefunden = false;
    //console.log(scene.children.length);

    for (let i=0; i < scene.children.length; i++){
        //console.log(i, scene.children[i].type);
        if (scene.children[i].type == 'Group' || scene.children[i].type == 'Object3D'  ){// ich suche genau ein Objekt in der Scene: die "Group"
            bbox.setFromObject(scene.children[i]);
            gefunden = true;
            break;// wenn man das "Group"-Element gefunden hat, kann man die for-Schleife verlassen
        }
    }
    if (gefunden==false){
        //console.log("nicht gefunden");
        bbox.max.set(0,0,0);
        bbox.min.set(0,0,0);
    }
    //console.log("bbox neu ", bbox);
}

export function setAmbientLight  (scene, intensity){
    //console.log("setAmbientLight", intensity);

    for (let i=0; i< scene.children.length; i++){
        if (scene.children[i].type == 'AmbientLight'){
            scene.children[i].intensity = intensity;
            return;// wenn man das "AmbientLight"-Element gefunden hat, kann man die for-Schleife verlassen
        }
    }
}

export function deleteAll(scene){
    for (let i= scene.children.length-1; i >= 0; i--){
        if (scene.children[i].type == 'Group' || scene.children[i].type == 'Object3D' ){// ich suche Objekte in der Scene vom Typ "Group"
            scene.remove(scene.children[i]);
        }
    }
    //console.log("scene deleteAll ", scene);
}



export function deleteCameras(scene){
    for (let i= scene.children.length-1; i >= 0; i--){
        if (scene.children[i].type == 'PerspectiveCamera' || scene.c[i].type == 'OrthographicCamera'){// ich suche alle Cameras
            scene.remove(scene.children[i]);
            //console.log("scene deleteCameras ", i);
        }
    }
    //console.log("scene deleteCameras ", scene);
}
export function setVisible(scene, id, visible){
    let object_in_scene = scene.children[id[0]];
    for (let i=1; i < id.length; i++){
        let a = id[i];
        object_in_scene = object_in_scene.children[a];
    }

    object_in_scene.visible = visible;
    console.log("object_in_scene", object_in_scene);


}

function  setVisibleGroup(child){
    for (let i= 0; i < child.children.length;i++){
        if (child.children[i].type == 'Group' || child.children[i].type == 'Object3D'){// ich suche alle Gruppen oder Object3D
            child.children[i].visible = true;
            setVisibleGroup(child.children[i]);
        }
        if (child.children[i].type == 'Mesh' ){

            child.children[i].visible = true;
        }
    }
}
export function setVisibleAll(scene){
    for (let i= scene.children.length-1; i >= 0; i--){
        if (scene.children[i].type == 'Group' || scene.children[i].type == 'Object3D'){// ich suche alle Gruppen

            scene.children[i].visible = true;
            setVisibleGroup(scene.children[i]);
        }
    }
}

// //before function:
// var mat_main  = scene.getObjectByName( 'K1').material.clone();
// var mat_wire =  mat_main.clone();
// mat_wire.wireframe = true;
    // oder ich setze die Farbe: //object_in_scene.material.color.set( Math.random() * 0xffffff );
    // so oder so: Man muss wohl die material Eigenschaft nicht auf Gruppen- sondern Mesh-Ebene durchführen
//
// //then inside function:
// if(intersects[ 0 ].object.name == 'K1' ) {
//     if(!intersects[ 0 ].object.material.wireframe){
//         intersects[ 0 ].object.material = mat_wire;
//     }else{
//         intersects[ 0 ].object.material = mat_main;
//     }
// }



function createTreeFromGroup(child, node){
    // children ist eine Group aus der Szene
    // node, wo die Daten eingefügt werden in den Tree
    node.name = child.name;
    for (let i= 0; i < child.children.length;i++){
        //console.log("child.children[i].type ", child.children[i].type);
        if (child.children[i].type == 'Group' || child.children[i].type == 'Object3D'){// ich suche alle Gruppen oder Object3D
            let newnode = { name: '', key: i, type: 0, expanded: false, visible: child.children[i].visible, id:[], childGroups: [], childContent:[]}
            for (let j=0; j < node.id.length; j++){
                newnode.id.push(node.id[j]);
            }
            newnode.id.push(i);

            createTreeFromGroup(child.children[i], newnode);
            node.childGroups.push(newnode);
        }
    }
    for (let i= 0; i < child.children.length;i++){
        if (child.children[i].type == 'Mesh' ){
            let newnode = { name: child.children[i].name, key: i, type: 0, expanded: true, visible: child.children[i].visible, id:[], childGroups: [], childContent:[]}
            for (let j=0; j < node.id.length; j++){
                newnode.id.push(node.id[j]);
            }
            newnode.id.push(i);
            node.childContent.push(newnode)
        }
    }
}
export function createTreeFromScene(scene, sceneTree, refreshTree){

    // sceneTree ist ein leere? Tree
    // refreshTree ist eine Callback-Funktion, um den Tree in den Treeview zu stecken.
    // type 0 = Gruppe
    // type 1 = views
    // type 2 = Lights
    // type 3 = content

    for (let i= sceneTree.length-1; i >= 0; i--){
        sceneTree.pop();
    }
    let views =
    { name: 'Views', key: 0, type: 1, expanded: false, childGroups: [
        { name: 'vorne' , key: 0, type: 1, expanded: true, visible: true, id:[0], childGroups: [], childContent:[] },
        { name: 'hinten', key: 1, type: 1, expanded: true, visible: true, id:[1], childGroups: [], childContent:[] },
        { name: 'oben'  , key: 2, type: 1, expanded: true, visible: true, id:[2], childGroups: [], childContent:[] },
        { name: 'unten' , key: 3, type: 1, expanded: true, visible: true, id:[3], childGroups: [], childContent:[] },
        { name: 'links' , key: 4, type: 1, expanded: true, visible: true, id:[4], childGroups: [], childContent:[] },
        { name: 'rechts', key: 5, type: 1, expanded: true, visible: true, id:[5], childGroups: [], childContent:[] },
        { name: 'iso1'  , key: 6, type: 1, expanded: true, visible: true, id:[6], childGroups: [], childContent:[] },
        { name: 'iso2'  , key: 7, type: 1, expanded: true, visible: true, id:[7], childGroups: [], childContent:[] },
        { name: 'iso3'  , key: 8, type: 1, expanded: true, visible: true, id:[8], childGroups: [], childContent:[] },
        { name: 'iso4'  , key: 9, type: 1, expanded: true, visible: true, id:[9], childGroups: [], childContent:[] },
    ], childContent:[] };
    let lights =
    { name: 'Lights', key: 0, type: 2, expanded: false, childGroups: [
        { name: 'Ambientlight', key: 0, type: 2, expanded: true, visible: true, id:[0], childGroups: [], childContent:[] },
        { name: 'Spotlight1'  , key: 1, type: 2, expanded: true, visible: true, id:[1], childGroups: [], childContent:[] },
        { name: 'Spotlight2'  , key: 2, type: 2, expanded: true, visible: true, id:[2], childGroups: [], childContent:[] },
    ], childContent:[] }
    sceneTree.push(views);
    sceneTree.push(lights);

    if (refreshTree==null)
        return;

    for (let i= scene.children.length-1; i >= 0; i--){
        if (scene.children[i].type == 'Group' || scene.children[i].type == 'Object3D'){// ich suche alle Gruppen
            let node = { name: '', key: i, type: 0, expanded: false, visible: scene.children[i].visible, id:[], childGroups: [], childContent:[]}
            node.id.push(i);
            createTreeFromGroup(scene.children[i], node);
            sceneTree.push(node);
        }
    }
    console.log("createTreeFromScene", scene);
    refreshTree(sceneTree);
}

export function setCameraDirection(direction,  control,  cameraLevel, camera, orbitControl, standardmatrix, bbox, rot_up_down_old){
    //console.log("direction ", direction, "control", control, "controls", orbitControl, "camlevel ", cameraLevel);

    let f=2;

    if (control == controlType.firstPersonControl){
        //console.log("direction ", direction);
        switch (direction){
            default:
            case cameraDirection.Vorne:
                //console.log("vorne");
                camera.position.set(0, cameraLevel, bbox.max.z * f);
                standardmatrix.set(1, 0, 0, 0,
                                   0, 1, 0, 0,
                                   0, 0, 1, 0,
                                   0, cameraLevel, bbox.max.z * f, 1);
                break;
            case cameraDirection.Hinten:
                //console.log("hinten");
                camera.position.set(0, cameraLevel, bbox.max.z * -f);
                standardmatrix.set(-1,  0,  0, 0,
                                    0,  1,  0, 0,
                                    0,  0, -1, 0,
                                    0, cameraLevel, bbox.max.z * -f, 1);
                break;
            case cameraDirection.Oben:
                //console.log("oben");// bei 1.Person mache ich hier nichts.
                return;
            case cameraDirection.Unten:
                //console.log("unten");// bei 1.Person mache ich hier nichts.
                return;
            case cameraDirection.Links:
                //console.log("links");
                camera.position.set(bbox.max.x * f, cameraLevel, 0);
                standardmatrix.set( 0,  0,  1, 0,
                                    0,  1,  0, 0,
                                    -1,  0, 0, 0,
                    bbox.max.x * f, cameraLevel, 0, 1);
                break;
            case cameraDirection.Rechts:
                //console.log("rechts");
                camera.position.set(bbox.max.x * -f, cameraLevel, 0);
                standardmatrix.set( 0,  0, -1, 0,
                                    0,  1,  0, 0,
                                    1,  0,  0, 0,
                    bbox.max.x * -f, cameraLevel, 0, 1);
                break;

            // bei den Isoansichten wird schräg Richtung Mitte der Scene geschaut, wobei die Camera auf der Grundebene bleibt (plus Cameralevel)
            // die Camera hat keine Neigung
            case cameraDirection.Iso1:// vorne rechts
                //console.log("Iso1");
                camera.position.set(bbox.max.x * -f, cameraLevel, bbox.max.z * f);//0.7071067
                standardmatrix.set( 0.7071067,  0, -0.7071067, 0,
                                    0,  1,  0, 0,
                                    0.7071067,  0,  0.7071067, 0,
                    bbox.max.x * -f, cameraLevel, bbox.max.z * f, 1);
                break;
            case cameraDirection.Iso2:// vorne links
                //console.log("Iso2");
                camera.position.set(bbox.max.x * f, cameraLevel, bbox.max.z * f);
                standardmatrix.set( 0.7071067,  0, 0.7071067, 0,
                                    0,  1,  0, 0,
                                    -0.7071067,  0,  0.7071067, 0,
                    bbox.max.x * f, cameraLevel, bbox.max.z * f, 1);
                break;
            case cameraDirection.Iso3:// hinten links
                //console.log("Iso3");
                camera.position.set(bbox.max.x * f, cameraLevel, bbox.max.z * -f);
                standardmatrix.set(-0.7071067,  0, 0.7071067, 0,
                                       0,  1,  0, 0,
                                    -0.7071067,  0,  -0.7071067, 0,
                    bbox.max.x * f, cameraLevel, bbox.max.z * -f, 1);
                break;
            case cameraDirection.Iso4:// hinten rechts
                //console.log("Iso4");
                camera.position.set(bbox.max.x * -f, cameraLevel, bbox.max.z * -f);
                standardmatrix.set( -0.7071067,  0, -0.7071067, 0,
                                    0,  1,  0, 0,
                                    0.7071067,  0,  -0.7071067, 0,
                    bbox.max.x * -f, cameraLevel, bbox.max.z * -f, 1);
                break;
        }
        camera.setRotationFromMatrix(standardmatrix);
        camera.fov = 40;//props.inputvalues.wheel_value;
        camera.near = 0.001;// bbox.max.z / 10000;
        camera.far = 10000.0;//bbox.max.z * 1000;
        camera.updateProjectionMatrix();

        // Wenn man einen Blick nach oben oder unten hatte, muss man den hier übernehmen:
        let x_vek = new THREE.Vector3();
        x_vek.set(standardmatrix.elements[0], 0, standardmatrix.elements[2]);
        x_vek.normalize();
        let delta = -rot_up_down_old * 85.0 * Math.PI / 180.0;
        camera.rotateOnWorldAxis(x_vek, delta);
    }
    else
    {   // OrbitControl
        // eslint-disable-next-line react-hooks/rules-of-hooks
        if (orbitControl == null)
            return;
        // console.log("bbox", bbox);

        switch (direction){
            default:
            case cameraDirection.Vorne:
                // console.log("v");
                camera.position.set(0, 0, bbox.max.z * f);
                break;
            case cameraDirection.Hinten:
                // console.log("h");
                camera.position.set(0, 0, bbox.max.z * -f);
                break;
            case cameraDirection.Oben:
                // console.log("o");
                camera.position.set(0, bbox.max.y * f, 0);
                break;
            case cameraDirection.Unten:
                // console.log("u");
                camera.position.set(0, bbox.max.y * -f, 0);
                break;
            case cameraDirection.Links:
                // console.log("l");
                camera.position.set(bbox.max.x * f, 0, 0);
                break;
            case cameraDirection.Rechts:
                // console.log("r");
                camera.position.set(bbox.max.x * -f, 0, 0);
                break;
            case cameraDirection.Iso1:
                // console.log("Iso1");
                camera.position.set(bbox.max.x * -f, bbox.max.y * f, bbox.max.z * -f);
                break;
            case cameraDirection.Iso2:
                // console.log("Iso2");
                camera.position.set(bbox.max.x *  f, bbox.max.y * f, bbox.max.z * -f);
                break;
            case cameraDirection.Iso3:
                // console.log("Iso3");
                camera.position.set(bbox.max.x *  f, bbox.max.y * f, bbox.max.z * f);
                break;
            case cameraDirection.Iso4:
                //console.log("Iso4");
                camera.position.set(bbox.max.x * -f, bbox.max.y * f, bbox.max.z * f);
                break;
        }
        orbitControl.update();
    }
}

function LoadModelPost(new_obj, scale,  control,  cameraLevel, camera, orbitControl, standardmatrix, bbox, scene, rot_up_down_old, sceneTree, refreshTree){
    new_obj.scale.set(scale, scale,scale);
    bbox.setFromObject(new_obj);
    scene.add(new_obj)
    let mittelpunkt= new THREE.Vector3();

    if (control == controlType.orbitControl)
        mittelpunkt.set((bbox.max.x + bbox.min.x) / 2, (bbox.max.y + bbox.min.y) / 2, (bbox.max.z + bbox.min.z) / 2);
    else
        mittelpunkt.set((bbox.max.x + bbox.min.x) / 2, 0, (bbox.max.z + bbox.min.z) / 2);// bei first-Person bleibe ich auf der xz-Ebene

    for (let i=0; i< scene.children.length; i++) {
        if (scene.children[i].type == 'Group'){
            scene.children[i].position.set(scene.children[i].position.x - mittelpunkt.x,
                scene.children[i].position.y - mittelpunkt.y, scene.children[i].position.z - mittelpunkt.z);
            break;
        }
    }
    // ich berechne die Boundingbox neu, da ich daraufhin die Cameraposition leicht außerhalb setzen kann.
    bbox.min.set(bbox.min.x - mittelpunkt.x, bbox.min.y - mittelpunkt.y, bbox.min.z - mittelpunkt.z);
    bbox.max.set(bbox.max.x - mittelpunkt.x, bbox.max.y - mittelpunkt.y, bbox.max.z - mittelpunkt.z);


    setCameraDirection(cameraDirection.Vorne, control, cameraLevel, camera, orbitControl, standardmatrix, bbox, rot_up_down_old);
    if (sceneTree)
    {
        createTreeFromScene(scene, sceneTree, refreshTree);
    }

}


export function LoadModel(file_scale, control, cameraLevel, camera, orbitControl, standardmatrix, bbox, scene, rot_up_down_old, sceneTree, refreshTree){
    //console.log("LoadModel in utils3d", scene, file_scale, bbox, control);
    if (file_scale.file == null || file_scale.file === "")
        return ;

    const suffix = file_scale.file.substr(file_scale.file.length - 3).toUpperCase();

    switch (suffix){
        case 'OBJ':
            const objLoader = new OBJLoader()
            objLoader.load(
                file_scale.file,
                (object) => {
                    LoadModelPost(object, file_scale.scale,  control,  cameraLevel, camera, orbitControl, standardmatrix, bbox, scene, rot_up_down_old, sceneTree, refreshTree);
                },
                (xhr) => {
                    //console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {console.log(error)}
            )
            break;
        case 'FBX':
            const fbxLoader = new FBXLoader()
            fbxLoader.load(
                file_scale.file,
                (object) => {
                    LoadModelPost(object, file_scale.scale,  control,  cameraLevel, camera, orbitControl, standardmatrix, bbox, scene, rot_up_down_old, sceneTree, refreshTree);
                },
                (xhr) => {//console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {console.log(error)}
            )
            break;
        case 'GLB':
        case 'LTF': // Dateiendung ist GLTF
            const gltfLoader = new GLTFLoader()
            gltfLoader.load(
                file_scale.file,
                function (gltf) {
                    let object = gltf.scene;
                    LoadModelPost(object, file_scale.scale,  control,  cameraLevel, camera, orbitControl, standardmatrix, bbox, scene, rot_up_down_old, sceneTree, refreshTree);
                },
                (xhr) => {//console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => { console.log(error)}
            )
            break;
        case 'STL':
            const material = new THREE.MeshPhysicalMaterial({
                color: 0xb2ffc8,
                envMap: '',//envTexture,
                metalness: 0.25,
                roughness: 0.1,
                opacity: 1.0,
                transparent: false,
                transmission: 0.99,
                clearcoat: 1.0,
                clearcoatRoughness: 0.25
            })
            const stlLoader = new STLLoader();
            stlLoader.load(
                file_scale.file,
                function (geometry) {
                    const mesh = new THREE.Mesh(geometry, material)
                    const object = new THREE.Object3D();
                    object.add(mesh);
                    LoadModelPost(object, file_scale.scale,  control,  cameraLevel, camera, orbitControl, standardmatrix, bbox, scene, rot_up_down_old, sceneTree, refreshTree);
                },
                (xhr) => {//console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {console.log(error)}
            )
            break;
    }
}