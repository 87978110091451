// von hier aus rufe ich die ThreeFiberkomponente auf
import ThreefiberComponent from "./ThreefiberComponent";
import Settings3D from "./Settings3D";
import  { useRef} from "react";
import TreeBase from "./TreeBase";
import {setVisibility} from "./Utils3D";



const ThreeSceneTreePage = () =>{
    const threefiberComponentRef = useRef(null);
    const treeBaseRef = useRef(null);

    let file_scale = {
        file: 'turan_villa.fbx',
        scale: 0.01
    };

    let sceneTree = [];// hier wird der gesamte Tree einer Scene gespeichert

    const refreshTree= (t)=>{
        // in t bekommt man den kompletten Tree, der aus einer Szene gewonnen wurde.
        // der Tree t wird dann an <TreeBase...> weitergereicht
        treeBaseRef.current.refreshTree(t);
    }

    const setVisibleFromTree = (node) =>{
        // node entspricht entweder einer Gruppe oder einem Mesh der Scene. Dort wird in visible der Zustand der Sichtbarkeit gehalten
        // das Objekt in der Scene wird über node.id identifiziert, was ein Array ist, mit den Id's von der Wurzel zu dem aktuellen Node
        threefiberComponentRef.current.setVisibilityInScene(node.id, node.visible);
    }

    return (
        <div >
            <Settings3D threefiberComponentRef={threefiberComponentRef}/>
            <div className={"ThreeSceneTreePage"}>
                <div>
                    <TreeBase sceneTree={sceneTree} ref={treeBaseRef} setVisibleFromTree={setVisibleFromTree} />
                </div>
                <div>
                    <ThreefiberComponent ref={threefiberComponentRef}
                                         file_scale    = {file_scale}
                                         control = {0}
                                         sceneTree = {sceneTree}
                                         refreshTree ={refreshTree}
                    />
                </div>
            </div>
        </div>
    );
}

export default ThreeSceneTreePage;
