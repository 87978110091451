const ContentNode = ({contentNode, displaymode, onContextmenu}) =>{

    const ContextMenu = (e)=>{
        e.preventDefault();

        let position = {x: e.pageX, y: e.pageY};
        onContextmenu(contentNode, position);
    }

    return (<>
        {<i className="fa fa-fw  fa-cubes" onContextMenu={ContextMenu}/>}
        {(displaymode==0) &&  <p onContextMenu={ContextMenu} style={{"display":"inline"}}>{contentNode.name}</p>}
    </>);
}

export default ContentNode;