const Input = (props) =>{
    document.addEventListener('keydown', (e) => OnKeyDown(e), false);
    document.addEventListener('keyup', (e) => OnKeyUp(e), false);
    document.addEventListener('wheel', (e) => WheelMouse(e), false);
    document.addEventListener('mousemove', (e) => MouseMove(e), false);
    const OnKeyDown = (event) => {
        //console.log("OnKeyDown");
        if (props.inputvalues.forward_backward != 0 || props.inputvalues.left_right != 0 || props.inputvalues.rot_left_right != 0)
            return;// aus einem mir nicht bekannten Grund wird keydown auch geworfen, wenn man die ganze Zeit die Tasten gedrückt hält

        switch (event.keyCode) {
            case 87: // w
                props.inputvalues.forward_backward  = -1.0;
                props.inputvalues.moveStartTimestamp = Date.now();
                break;
            case 65: // a
                props.inputvalues.left_right  = -1.0;
                props.inputvalues.moveStartTimestamp = Date.now();
                break;
            case 83: // s
                props.inputvalues.forward_backward = 1.0;
                props.inputvalues.moveStartTimestamp = Date.now();
                break;
            case 68: // d
                props.inputvalues.left_right  = 1.0;
                props.inputvalues.moveStartTimestamp = Date.now();
                break;
            case 81: // q
                props.inputvalues.rot_left_right  = 1.0;
                props.inputvalues.rotStartTimestamp = Date.now();
                break;
            case 69: // e
                props.inputvalues.rot_left_right  = -1.0;
                props.inputvalues.rotStartTimestamp = Date.now();
                break;
        }
    }

    const OnKeyUp = (event) =>{
        //console.log("OnKeyUP");
        switch(event.keyCode) {
            case 87: // w
            case 83: // s
                props.inputvalues.forward_backward  = 0.0;
                break;
            case 65: // a
            case 68: // d
                props.inputvalues.left_right  = 0.0;
                break;
            case 81: // q
            case 69: // e
                props.inputvalues.rot_left_right  = 0.0;
                break;
            case 32:// mit Space wird das hoch und runterschauen ein- und ausgeschaltet.
                //console.log("space");
                if (props.inputvalues.enable_up_down == false)
                    props.inputvalues.enable_up_down = true;
                else
                    props.inputvalues.enable_up_down = false;
                break;
        }
    }

    const WheelMouse = (event) =>{
        //console.log("WheelMouse");
        if (event.deltaY > 0) {
            props.inputvalues.wheel_value *= 1.01;
        }
        else {
            props.inputvalues.wheel_value /= 1.01;
        }
        //console.log("WheelMouse", props.inputvalues.wheel_value);
        props.inputvalues.setWheel = true;
    }

    const MouseMove = (event) =>{
        //let x = event.clientX / window.innerWidth - 0.5; // der horizontale Winkel wird erstmal nicht ausgewertet
        let y = event.clientY / window.innerHeight - 0.5;
        //console.log("Mousemove ", event.clientY, window.innerHeight, y)
        props.inputvalues.rot_up_down =  y * 2.0;
    }
}// Input


export default Input;