import TreeView from "./TreeView";
import ContentView from "./ContentView";

const TreeNode = ({treeNode, toggleExpanded, toggleVisible, displaymode, onContextmenu}) => {

    const handleExpandClick = ()=>{
        toggleExpanded(treeNode);
    }
    const handleVisibleClick = ()=>{
        toggleVisible(treeNode);
    }


    const ContextMenu = (e)=>{
        e.preventDefault();
        let position = {x: e.pageX, y: e.pageY};
        onContextmenu(treeNode, position);
    }



    return (
        <li className="TreeNode" key={treeNode.key}>
            {!(treeNode.expanded) && <i className="fa fa-fw fa-caret-right" onClick={handleExpandClick} onContextMenu={ContextMenu}/>}
            {treeNode.expanded && <i className="fa fa-fw fa-caret-down" onClick={handleExpandClick} onContextMenu={ContextMenu}/>}
            {treeNode.type==0 && <i className="fa fa-fw fa-folder-o" onContextMenu={ContextMenu}/>}
            {treeNode.type==1 && <i className="fa fa-fw fa-camera" onContextMenu={ContextMenu}/>}
            {treeNode.type==2 && <i className="fa fa-fw fa-lightbulb-o" onContextMenu={ContextMenu}/>}

            {(treeNode.visible && treeNode.type==0) && <i className="fa fa-fw fa-eye" onClick={handleVisibleClick} onContextMenu={ContextMenu}/>}
            {(!treeNode.visible && treeNode.type==0) && <i className="fa fa-fw fa-eye-slash" onClick={handleVisibleClick} onContextMenu={ContextMenu}/>}


            <p onContextMenu={ContextMenu}  style={{"display":"inline"}}>{treeNode.name}</p>
            {(treeNode.expanded && treeNode.childGroups) &&  <TreeView treeNodes={treeNode.childGroups}
                                                                       toggleExpanded={toggleExpanded}
                                                                       toggleVisible={toggleVisible}
                                                                       displaymode={displaymode}
                                                                       onContextmenu={onContextmenu}
                                                             />}
            {(treeNode.expanded && treeNode.childContent) && <ContentView contentNodes={treeNode.childContent}
                                                                          toggleVisible={toggleVisible}
                                                                          displaymode={displaymode}
                                                                          onContextmenu={onContextmenu}
                                                             />}
        </li>
    );
}
export default TreeNode;