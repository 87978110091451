//import React from "react";
import {useFrame, useThree} from "@react-three/fiber";
import * as THREE from "three";
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import {controlType} from "./controlType";
import {forwardRef, useImperativeHandle} from "react";
import {
    getIntersection,
    setAmbientLight,
    deleteAll,
    LoadModel,
    setCameraDirection,
    setVisible,
    setVisibleAll,
    identifyObject
} from "./Utils3D";



const RenderLoop = forwardRef ((props, ref) => {


    useImperativeHandle(ref, ()=>({
        identifyObject(props){console.log("renderloop identifyObjekt", props); return identifyObject(scene, props.x, props.y, camera, renderer); }
    }));

    // über use Three kann man sich die wesentlichen Basisobjekte abholen:
    const camera = useThree(state => state.camera);// Defaultcamera
    const scene = useThree(state => state.scene);// Defaultscene
    const renderer = useThree(state => state.gl);// Default-Renderer
    let orbitControl;


    const downvector = new THREE.Vector3(0, -1, 0 );
    let rot_up_down_old = 0.0;
    let x_vek= new THREE.Vector3();
    let z_vek= new THREE.Vector3();
    let standardmatrix = new THREE.Matrix4(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, props.cameraLevel, 5, 1);
    let bbox = new THREE.Box3();

    useFrame(() => {
        if (props.file_scale.setFileScale == true) {
            props.file_scale.setFileScale = false;
            console.log("bbox ", bbox);
            deleteAll(scene);
            LoadModel(props.file_scale, props.inputvalues.controltype, props.inputvalues.cameraLevel, camera, orbitControl, standardmatrix, bbox,
                scene, rot_up_down_old, props.sceneTree, props.refreshTree);
        }

        if (props.inputvalues.updateCamera == true){
            //console.log("update camera ", props.inputvalues.canvas_width / props.inputvalues.canvas_heigth);
            props.inputvalues.updateCamera = false;

            camera.aspect = props.inputvalues.canvas_width / props.inputvalues.canvas_heigth;
            camera.updateProjectionMatrix();
            renderer.setSize(props.inputvalues.canvas_width, props.inputvalues.canvas_heigth);
        }

        if (props.inputvalues.setAmbientLight == true){
            props.inputvalues.setAmbientLight = false;
            setAmbientLight(scene, props.inputvalues.ambientLight);
        }

        if (props.inputvalues.setCamDirection == true){
            props.inputvalues.setCamDirection = false;
            console.log("setCamDirection " , props.inputvalues.camDirection);
            setCameraDirection(props.inputvalues.camDirection, props.inputvalues.controltype,
                 props.inputvalues.cameraLevel, camera, orbitControl, standardmatrix, bbox, rot_up_down_old);

        }

        if (props.inputvalues.setControl == true){
            props.inputvalues.setControl = false;
            console.log("setControl " , props.inputvalues.controltype, props);
            if (props.inputvalues.controltype == controlType.firstPersonControl){

                if (orbitControl != null){
                    console.log("dispose");
                    orbitControl.dispose();
                }
                setCameraDirection(props.inputvalues.camDirection, props.inputvalues.controltype,
                    props.inputvalues.cameraLevel, camera, orbitControl, standardmatrix, bbox, rot_up_down_old);
            }

            if (props.inputvalues.controltype == controlType.orbitControl){
                console.log("new Orbitcontrol");
                orbitControl = new OrbitControls( camera, renderer.domElement );
            }
        }

        if (props.inputvalues.setVisible == true){
            console.log("setvisible in renderloop", props.inputvalues.id, props.inputvalues.visible);
            props.inputvalues.setVisible = false;
            setVisible(scene, props.inputvalues.id, props.inputvalues.visible);
        }

        if (props.inputvalues.allesEinblenden == true){
            props.inputvalues.setVisible = false;
            setVisibleAll(scene);
        }


        if (props.inputvalues.controltype == controlType.firstPersonControl) {
            // die 4x4 Matrix beschreibt die Orientierung der Camera im Raum. Davon hole ich mir das Bild des X und des Z Vektors ab.
            // Warum? sobald man eine Drehung der Camera gemacht hat, ist nach links gehen oder nach vorne usw. nicht mehr kompatibel zum
            // Weltkoordinatensystem, sondern nur noch zum lokalen Koordinatensystem der Camera.
            // x_vek: links / rechts, z_vek: vor / zurück
            // Projektion der Richtungsvektoren auf die Basisfläche (XZ) -> Y-Werte = 0
            x_vek.set(camera.matrix.elements[0], 0, camera.matrix.elements[2]);
            z_vek.set(camera.matrix.elements[8], 0, camera.matrix.elements[10]);
            x_vek.normalize();
            z_vek.normalize();

            if (props.inputvalues.forward_backward != 0.0 || props.inputvalues.left_right != 0.0) {
                let timestamp_now = Date.now();
                let time = timestamp_now - props.inputvalues.moveStartTimestamp;
                props.inputvalues.moveStartTimestamp = timestamp_now;
                let d = time * props.inputvalues.velocity;

                if (props.inputvalues.forward_backward !== 0.0) {
                    z_vek.multiplyScalar(d * props.inputvalues.forward_backward);
                    let dd = getIntersection(scene, camera.position, z_vek);
                    if (dd > d || dd == -1.0) {
                        camera.position.add(z_vek);
                    }
                }

                if (props.inputvalues.left_right != 0.0) {
                    x_vek.multiplyScalar(d * props.inputvalues.left_right);
                    let dd = getIntersection(scene, camera.position, x_vek);
                    if (dd > d || dd == -1.0) {
                        camera.position.add(x_vek);
                    }
                }

                // hier bestimme ich die Fläche, auf der man sich gerade befindet. Die Kamera wird dann über diese Fläche positioniert mit der Höhe  props.inputvalues.cameraLevel
                let di = getIntersection(scene, camera.position, downvector);

                if (di == -1) {
                    camera.position.y = props.inputvalues.cameraLevel;// wenn es keinen Schnittpunkt gibt, gehe ich von der Grundebene aus.
                } else {
                    camera.position.y = props.inputvalues.cameraLevel + downvector.y * di + camera.position.y;
                }
            }

            if (props.inputvalues.rot_left_right != 0.0) {
                //console.log("input in rot_left_right", props.inputvalues.rot_left_right, props.inputvalues.rot_up_down);
                let timestamp_now = Date.now();
                let time = timestamp_now - props.inputvalues.rotStartTimestamp;
                //console.log("Time ", time);
                props.inputvalues.rotStartTimestamp = timestamp_now;
                // da man ja auch nach oben oder unten schauen kann, ist der lokale y Vektor der Camera nicht immer identisch mit dem des
                // Weltkoordiantensystems. Rotiert wird also mit dem Weltkoordinatensystem
                let delta = -props.inputvalues.rot_left_right * 0.03 * time * Math.PI / 180.0;// max 30 Grad pro Sekunde
                camera.rotateOnWorldAxis(downvector, delta);
                //invalidate();
            }


            if (props.inputvalues.enable_up_down == true) {
                //console.log("up_down");
                // ich erhalte Werte zwischen -1 und 1
                // diese werden auf -85° - +85° gemappt. Ich möchte nicht, dass man senkrecht nach oben schauen kann, damit ein gewisser
                // "Restvektor" in der xz Ebene nach vorne schaut.


                let delta = (rot_up_down_old - props.inputvalues.rot_up_down) * 85.0 * Math.PI / 180.0;
                //die Rotationsachse ist der x_vek, der in der XZ Ebene liegt.
                x_vek.normalize();
                camera.rotateOnWorldAxis(x_vek, delta);
                rot_up_down_old = props.inputvalues.rot_up_down;

               // console.log("delta ", delta, rot_up_down_old , props.inputvalues.rot_up_down, x_vek);
            }

            // zoom:
            if (props.inputvalues.setWheel == true) {
                console.log("wheel ", props.inputvalues.wheel_value);
                camera.fov = props.inputvalues.wheel_value;
                props.inputvalues.wheel_used = false;
                camera.updateProjectionMatrix();
            }
        }
    })//UseFrame Ende

    return null;
});


export default RenderLoop;