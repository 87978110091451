import React from 'react'

import {cameraDirection} from './CameraDirection.js'


const Settings3D = (props) =>{
    let file_scale = {
        file: 'turan_villa.fbx',
        scale: 0.01
    };
    const handleFilenameChange = (file) => {
        file_scale.file = file;

        console.log("handleFilenameChange", file_scale )
        props.threefiberComponentRef.current.setFile_Scale(file_scale);
    }
    const handleControlChange = (control) => {
        // 0 = 1.Person Control
        // 1 = OrbitControl
        props.threefiberComponentRef.current.setControl(control);
    }

    // const handleEnvironmentChange = (env) => {
    //     props.threefiberComponentRef.current.setEnvironment(env);
    // }
    const handleAmbientlightChange = (ambientlight) => {
        props.threefiberComponentRef.current.setAmbientLight(ambientlight);
    }
    const handleCameralevelChange = (level) => {
        props.threefiberComponentRef.current.setCameraLevel(parseFloat(level));
    }
    const handleVelocityChange= (velocity) => {
        // Geschwindigkeit beim Bewegen mit 1.st Person Control
        props.threefiberComponentRef.current.setVelocity(velocity);
    }
    const handleScaleChange= (s) => {
        // die Skalierung der Szene beim laden
        file_scale.scale = parseFloat(s);
        console.log("handleScaleChange" , file_scale.scale);
    }

    const handleOnCameraDirectionClick=(direction) =>{
        props.threefiberComponentRef.current.setCameraDirection(direction);
    }

    return (
        <div>
            <label>File:</label>
            <select value={props.filename} onChange={event=> handleFilenameChange(event.target.value)}>
                <option>Residential Buildings 001.fbx</option>
                <option>Residential Buildings 002.fbx</option>
                <option>E5.obj</option>
                <option>E6.obj</option>
                <option>test.gltf</option>
                <option>turan_villa.fbx</option>
                <option>eren_villa.fbx</option>
                <option>Bauteil1.glb</option>
                <option>klotz1.glb</option>
                <option>Büro_CAD-3D-Ansicht.fbx</option>
                <option>SKM_Gebäude-3D-Ansicht_2015.fbx</option>
                <option>SKM_Gebäude-3D-Ansicht.fbx</option>
                <option>E00001222.obj</option>
                <option>E00001222.stl</option>
                <option>fusslager.fbx</option>
                <option>fusslager.glb</option>


            </select>
            <label>Skalierung:</label>
            <select value={props.scale} onChange={event=> handleScaleChange(event.target.value)}>
                <option>0.001</option>
                <option>0.01</option>
                <option>0.1</option>
                <option>1</option>
                <option>10</option>
                <option>100</option>
                <option>1000</option>
                <option>10000</option>
                <option>100000</option>
                <option>1000000</option>
                <option>10000000</option>
            </select>

            <label>Helligkeit:</label>
            <select value={props.ambientlight} onChange={event=> handleAmbientlightChange(event.target.value)}>
                <option>0.1</option>
                <option>0.3</option>
                <option>0.5</option>
                <option>0.7</option>
                <option>0.9</option>
                <option>1.0</option>
                <option>3.0</option>
            </select>

            {/*<label>360° Panorama:</label>*/}
            {/*<select value={props.environment} onChange={event=> handleEnvironmentChange(event.target.value)}>*/}
            {/*    <option>apartment</option>*/}
            {/*    <option>city</option>*/}
            {/*    <option>dawn</option>*/}
            {/*    <option>forest</option>*/}
            {/*    <option>lobby</option>*/}
            {/*    <option>night</option>*/}
            {/*    <option>park</option>*/}
            {/*    <option>studio</option>*/}
            {/*    <option>sunset</option>*/}
            {/*    <option>warehouse</option>*/}
            {/*    <option></option>*/}
            {/*</select>*/}

            <label>Camlevel:</label>
            <select value={props.cameralevel} onChange={event=> handleCameralevelChange(event.target.value)}>
                <option>0.5</option>
                <option>1.0</option>
                <option>1.5</option>
                <option>1.7</option>
                <option>2.0</option>
                <option>5.0</option>
            </select>

            <label>Velocity:</label>
            <select value={props.velocity} onChange={event=> handleVelocityChange(event.target.value)}>
                <option>0.00125</option>
                <option>0.0025</option>
                <option>0.005</option>
                <option>0.01</option>
                <option>0.02</option>
                <option>0.04</option>
            </select>

            <label>Control:</label>
            <select value={props.control} onChange={event=> handleControlChange(event.target.value)}>
                <option label='1st Person Control'>0</option>
                <option label='Orbit Control'>1</option>
            </select>
            <br/>
            <button onClick={event => handleOnCameraDirectionClick(cameraDirection.Vorne)}>Vorne</button>
            <button onClick={event => handleOnCameraDirectionClick(cameraDirection.Hinten)}>Hinten</button>
            <button onClick={event => handleOnCameraDirectionClick(cameraDirection.Oben)}>Oben</button>
            <button onClick={event => handleOnCameraDirectionClick(cameraDirection.Unten)}>Unten</button>
            <button onClick={event => handleOnCameraDirectionClick(cameraDirection.Links)}>Links</button>
            <button onClick={event => handleOnCameraDirectionClick(cameraDirection.Rechts)}>Rechts</button>
            <button onClick={event => handleOnCameraDirectionClick(cameraDirection.Iso1)}>Iso1</button>
            <button onClick={event => handleOnCameraDirectionClick(cameraDirection.Iso2)}>Iso2</button>
            <button onClick={event => handleOnCameraDirectionClick(cameraDirection.Iso3)}>Iso3</button>
            <button onClick={event => handleOnCameraDirectionClick(cameraDirection.Iso4)}>Iso4</button>
        </div>
    );
}

export default Settings3D;