import ContentNode from "./ContentNode";

const ContentView = ({contentNodes, toggleVisible, displaymode, onContextmenu}) =>{

    if (displaymode == 1){
        return (<ul>
                    <li className='TreeNode' >
                        {// ein Listitem für alle ContenNodes
                            contentNodes.map(cn => (<ContentNode contentNode={cn} displaymode={displaymode} onContextmenu={onContextmenu}/>))
                        }
                    </li>
                </ul>);
    }
    else{
        return (<ul>
                { //für jeden Contentnode ein Listitem
                    contentNodes.map(cn => (
                    <li className='TreeNode' key={cn.key}>
                        <ContentNode contentNode={cn} displaymode={displaymode} onContextmenu={onContextmenu}/>
                    </li>))
                }
                </ul>);
    }
}

export default ContentView;