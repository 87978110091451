import {useEffect} from "react";

const CirclesPage = () =>{
    let canvas = document.querySelector("canvas");
    let canvasContext = null;
    let width = 0;
    let height = 0;
    var circlearr = [];


    useEffect(() =>{// diese Funktion wird aufgerufen, beim Rendering, egal was gerendert wird.
        // wenn man das nicht möchte, muss man weitere Parameter angeben (s.u.)
        canvas = document.querySelector("canvas");

        const handleResize = e => {
            canvas.height = height = window.innerHeight * 0.9;
            canvas.width = width = window.innerWidth * 0.9;
            canvasContext = canvas.getContext("2d");

            animate_circle();
        };

        handleResize();
        window.addEventListener("resize", handleResize);
    },[])

    window.addEventListener("mousemove",
        function (event){

            if (canvas ){
                var rect = canvas.getBoundingClientRect();
                if (event.x >=rect.left && event.x < rect.right && event.y >= rect.top && event.y < rect.bottom) {
                    console.log("rtop", rect.top, "rleft", rect.left, "rbottom", rect.bottom, "rright", rect.right, "x y ", event.x, event.y);
                    console.log("umgerechnet auf das Canvaselement", event.x-rect.left, event.y-rect.top);
                    console.log("Array", circlearr.length);
                }
            }
        });

    window.addEventListener(("mouseup"),
        function(event){console.log("Mouseup");}
    );

    var colorArray=[
        '#ffaa33','#99ffaa','#00ff00','#0000ff','#00000f','#fffff0'
    ];

    function Circle(x,y,r, dx, dy, v){
        this.x = x;
        this.y = y;
        this.r = r;
        this.dx = dx;
        this.dy = dy;
        this.v = v;
        this.color = colorArray[Math.floor(Math.random()*colorArray.length)];

        this.draw = function (){
            canvasContext.beginPath();
            canvasContext.arc(this.x, this.y, this.r, 0, 2*Math.PI, false);
            canvasContext.fillStyle = this.color;
            canvasContext.fill();


            this.x = this.x + this.dx;
            this.y = this.y + this.dy;
            if (this.x > width  - this.r)
                this.dx = -this.v + Math.random() * 0.1 - 0.5;

            if (this.x < this.r)
                this.dx = this.v + Math.random() * 0.1 - 0.5;

            if (this.y > height -this.r)
                this.dy = -this.v + Math.random() * 0.1 - 0.5;

            if (this.y < this.r)
                this.dy = this.v + Math.random() * 0.1 - 0.5;
        }
    }

    for (let i=0; i<20; i++){
        let x= Math.random()*width;
        let y=Math.random()*height;
        let r=Math.random()*100;
        let dx = Math.random()*2;
        let dy = Math.random()*2;
        let velocity = Math.random()*10;

        var circle1 = new Circle(x, y, r, dx, dy, velocity);
        circlearr.push(circle1);
    }

    function animate_circle(){
        requestAnimationFrame(animate_circle);
        canvasContext.clearRect(0,0,window.innerWidth, window.innerHeight);

        for (let i=0; i < circlearr.length; i++)
            circlearr[i].draw();
    }

    return (
        <div className="circles">
            <canvas></canvas>
        </div>
    );
}

export default CirclesPage;