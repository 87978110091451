import { BrowserView } from 'react-device-detect';
import {Link} from 'react-router-dom';

const NavBar = () =>{
    return (

            <nav className="navbar">
                <h1>Falo3D</h1>
                <div className="links">
                    {/*wenn man mit <a... arbeitet dann sind das Hyperlinks,
                     die zum Server gesendet werden.Wir benötigen aber das weiterleiten
                     innerhalb der React-Applikation, ohne Serverzugriff. Nur wenn ich
                     einen Link zu einer externen Seite hätte, wäre <a... richtig*/}


                    {/*<Link to="/circles" style={{*/}
                    {/*    color: "white",*/}
                    {/*    backgroundColor: 'black',*/}
                    {/*    borderRadius: '8px'*/}
                    {/*}}>Circles</Link>*/}

                    <Link to="/contact" style={{
                        color: "white",
                        backgroundColor: 'black',
                        borderRadius: '8px'
                    }}>Kontakt</Link>

                    <Link to="/three_scene" style={{
                        color: "white",
                        backgroundColor: 'black',
                        borderRadius: '8px'
                    }}>3D Scene</Link>

                    <Link to="/three_scene_tree" style={{
                        color: "white",
                        backgroundColor: 'black',
                        borderRadius: '8px'
                    }}>3D Scene Tree</Link>
                </div>
            </nav>

    );
}
//<BrowserView> </BrowserView>
export default NavBar;