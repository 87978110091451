export const cameraDirection ={
    Vorne:  0,
    Hinten: 1,
    Oben:   2,
    Unten:  3,
    Links:  4,
    Rechts: 5,
    Iso1:   6,
    Iso2:   7,
    Iso3:   8,
    Iso4:   9
};