import {Link} from "react-router-dom";

const NotFoundPage=()=>{

    return (
        <div className="notfound">
            <h1>not found</h1>
            <Link to="/">Home</Link>
        </div>
    );
}

export default NotFoundPage();